<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-subtitle>
      How to get the location for weather
    </v-card-subtitle>
    <v-card-text>
      In order to use this weather widget you must first get a link from weather widget io's website for the location you would like to display. First, please <a href="https://weatherwidget.io" target="_blank">click here</a> to go to weather widget io's website. There under settings you will see the "Select Location" input where you can search for the location you need. After selecting the location, press on the GET CODE button just below. You will see a code block with a <strong>href</strong> attribute and it will start with <i>https://forecast7.com/...</i>. Please copy this link without the quotation marks (") and paste it below.
    </v-card-text>
    <v-card-text>
      <validation-provider
        v-slot="{ errors }"
        rules="required|url"
        name="Href"
      >
        <v-text-field
          v-model="value.href"
          outlined
          label="Weather Widget Io Href"
          hint="The href attribute that is given by weatherwidget.io, depending on the location."
          required
          :error-messages="errors"
        />
      </validation-provider>
      <v-text-field
        v-model="value.primaryLabel"
        outlined
        label="Primary Label"
        hint="This is the upper label shown on the widget"
      />
      <v-text-field
        v-model="value.secondaryLabel"
        outlined
        label="Secondary Label"
        hint="Secondary label that appears below the primary label."
      />
      <v-select
        v-model="value.theme"
        outlined
        :items="availableThemes"
        label="Theme"
        hint="The href attribute that is given by weatherwidget.io, depending on the location."
        required
      />
      <v-select
        v-model="value.font"
        outlined
        :items="availableFonts"
        label="Font"
        clearable
      />
      <v-select
        v-model="value.icons"
        outlined
        :items="availableIcons"
        label="Icons"
        clearable
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WeatherWidgetIoForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    availableThemes: [
      'original',
      'pure',
      'ruby',
      'pattern',
      'kiwi',
      'pool_table',
      'weather_one',
      'random_grey',
      'tile_wood'
    ],
    availableFonts: [
      'Arial',
      'Arial Black',
      'Arial Narrow',
      'Arial Rounded MT Bold',
      'Arimo',
      'Consolas',
      'Courier New',
      'Fira Sans',
      'Georgia',
      'Helvetica',
      'Jura',
      'Monaco',
      'Noto Sans',
      'Noto Serif',
      'Open Sans',
      'Open Sans Condensed',
      'Palatino',
      'Play',
      'Roboto',
      'Roboto Slab',
      'Tahoma',
      'Times New Roman',
      'Tinos',
      'Trebuchet MS',
      'Ubuntu',
      'Verdana'
    ],
    availableIcons: [
      'Iconvault',
      'Climacons'
    ]
  })
}
</script>
